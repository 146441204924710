import React, { useState } from "react"
import VideoBlock from "./VideoBlock"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"

const ContentContainer = styled.div`
  padding-top: 47.4px;
  padding-bottom: 71.8px;
  @media(min-width: ${breakpoints.md}){
    padding-top: 105px;
    padding-bottom: 31px;
  }
`

const VideoPage = ({blocks}) => {
  const [currentVideoPlaying, setCurrentVideoPlaying] = useState(null)

  return (
        <ContentContainer>
          {blocks.map((block, index) => {
            return (
              <VideoBlock key={index}
                          block={block}
                          videoIndex={index}
                          setCurrentVideoPlaying={setCurrentVideoPlaying}
                          currentVideoPlaying={currentVideoPlaying}/>
            )
          })}
        </ContentContainer>
  )
}

export default VideoPage
