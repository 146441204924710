import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import CoreContainer from "../components/Core/CoreContainer"
import { graphql } from "gatsby"
import Videos from "../components/Video/VideoPage"

const VideoPage = (props) => {
  const path = props.location.pathname
  const page = props.data.allContentfulFilmPage.edges[0].node
  let blocks = page.videoBlock

  return (
    <Layout>
      <SEO title={page.metaTitle}
           description={page.metaDescription}
           fullTitle={false}
           path={path}/>
      <CoreContainer>
        <Videos blocks={blocks} />
      </CoreContainer>
    </Layout>
  )
}

export default VideoPage

export const pageQuery = graphql`
  query VideopageQuery {
    allContentfulFilmPage {
      edges {
        node {
          metaTitle
          metaDescription
          videoBlock {
            linkLabel
            linkUrl
            title
            location
            description {
              json
            }
            thumbnail {
              file {
                url
              }
            }
            video {
              file {
                contentType
                fileName
                url
              }
            }
          }
        }
      }
    }
  }
`
