import React, { useRef, useState, useEffect } from "react"
import PauseIcon from "../../resources/img/ui/pause.svg"
import PlayIcon from "../../resources/img/ui/play.svg"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"

const VideoIcon = styled.img`
  width: 39.75px;
  height: auto;
  position: ${(props) => (props.full ? "fixed" : "absolute")};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  z-index: ${(props) => (props.full ? 1000 : 1)};
  top: ${(props) => (props.full ? "50vh" : "50%")};
  transform: translateY(-50%);
  opacity: ${props => props.isVideoPlaying ? 0 : 1};
  transition: opacity .4s;

  @media (min-width: ${breakpoints.md}) {
    width: ${(props) => (props.playing ? "50px" : "53px")};
    height: auto;
  }
`

const VideoFrame = styled.video`
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (min-width: ${breakpoints.md}) {
    position: ${(props) => props.isFull && "absolute"};
    left: ${(props) => props.isFull && "50%"};
    top: ${(props) => props.isFull && "50%"};
    transform: ${(props) => props.isFull && "translate(-50%, -50%)"};
    width: ${(props) => (props.isFull ? "auto" : "100%")};
  }
`

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.full &&
    "width: 100vw; height: 100vh; background: #FFF; position: fixed; top: 0; left: 0; z-index: 999; padding: 0;"}
  video {
    object-fit: contain;
  }
  @media (min-width: ${breakpoints.md}) {
  }
`

const Container = styled.div`
  position: relative;
`

const Video = ({ video, isPlaying, setPlay, isFullScreen, thumbnail }) => {
  const [pauseHidden, setPauseHidden] = useState(true)
  const videoRef = useRef(null)
  let secondsSincesLastActivity = 0
  const maxInactivity = 3
  let timer;

  if (videoRef.current) {
    if (isPlaying === true) {
      videoRef.current.play()
    } else if (isPlaying === false) {
      videoRef.current.pause()
    }
  }

  let iconImage

  if (!isPlaying) {
    iconImage = PlayIcon
  } else if (isPlaying) {
    iconImage = PauseIcon
  }

  const activityWatcher = () => {
    timer = setInterval(() => {
      secondsSincesLastActivity++
      if (secondsSincesLastActivity > maxInactivity) {
        setPauseHidden(false)
        clearInterval(timer)
      }
    }, 1000)

    const activity = () => {
      setPauseHidden(true)
      secondsSincesLastActivity = 0
    }
    videoRef.current.addEventListener("mousemove", activity)
    videoRef.current.addEventListener("touchstart", activity)
  }

  useEffect(() => {
    activityWatcher()
  })

  const handleButtonClick = () => {
    setPlay(!isPlaying)
    setPauseHidden(true)
    clearInterval(timer)
  }

  return (
    <Container>
        <VideoIcon
          src={iconImage}
          alt={isPlaying ? "Stop Video" : "Play Video"}
          onClick={() => handleButtonClick()}
          full={isFullScreen}
          isVideoPlaying={isPlaying && !pauseHidden}
          playing={isPlaying}
        />
      <VideoContainer full={isFullScreen}>
        {thumbnail ? (
          <VideoFrame
            src={video}
            ref={videoRef}
            poster={thumbnail}
            playsInline
            preload="auto"
            controls={false}
            loop={true}
            isFull={isFullScreen}
            type="video/mp4"
          />
        ) : (
          <VideoFrame
            src={video + "#t=0.1"}
            isFull={isFullScreen}
            ref={videoRef}
            playsInline
            preload="auto"
            controls={false}
            loop={true}
            type="video/mp4"
          />
        )}
      </VideoContainer>
    </Container>
  )
}

export default Video
