import React from 'react'
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"

const StyledContainer = styled.div`
  margin-top: 80px;
  padding: 0 18px;
  @media(min-width: ${breakpoints.lg}) {
    margin-top: 120px;
    padding: 0 73px;
  }
`

const CoreContainer = ({children}) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}

export default CoreContainer
