import React, { useState } from "react";
import ExternalLink from "../Core/ExternalLink";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import InfoImg from "../../resources/img/ui/info.svg";
import breakpoints from "../../styles/breakpoints";
import Video from "./Video";
import CloseIcon from "../../resources/img/ui/close.svg"

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.full ? '0' : '12px'};
  padding-right: ${props => props.full ? '0' : '12px'};
  justify-content: center;
  
  @media(min-width: ${breakpoints.md}){
    padding-left: 0;
    padding-right: 0;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 224px;
  }
`;

const ContentContainer = styled.div`
  padding-top: 16.4px;
  display: ${props => props.full ? "none" : "block"};

  @media(min-width: ${breakpoints.md}){
    display: ${props => props.full ? "none" : "flex"};
    flex-direction: column;
    padding-top: 0;
    padding-left: 18px;
    padding-bottom: 18px;
    width: 140px;
    position: absolute;
    right: -140px;
    bottom: 0;
  }
  
  @media(min-width: ${breakpoints.lg}){
    width: 216px;
    right: -216px;
  }
`;

const Text = styled.div`
  ${fonts.nhassGroteskRoman};
  font-size: 12.48px;
  line-height: 21.216px;
  letter-spacing: 0.5px;
  color: ${colors.black};
  
  a {
    text-decoration: none;
    color: ${colors.black};
  }
  
  @media(min-width: ${breakpoints.md}){
    font-size: 9.23px;
    line-height: 16px;
  }
`;

const Description = styled(Text)`
  display: ${props => props.isDescriptionVisible ? 'block' : 'none'};
  padding-top: 13px;
  padding-bottom: 47px;
  
  @media(min-width: ${breakpoints.md}){
    padding-top: 0;
    padding-bottom: 25px;
    order: 1;
  }
`;

const FullScreenButton = styled.div`
  ${fonts.nhassGroteskBold};
  font-size: 9px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  color: ${colors.black};
  display: inline-block;
  float: right;
  
  @media(min-width: ${breakpoints.md}){
    order: 3;
  }
`;

const InfoIcon = styled.div`
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-bottom: 10.5px;
  cursor: pointer;
  img {
    height: 15px;
    width: 15px;
  }
  
  @media(min-width: ${breakpoints.md}){
    order: 1;
    margin-bottom: 8px;
  }
`;

const VideoBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Block = styled.div`
  position: relative;
  width: ${props => props.full ? "100%" : "100%"};
  max-width: ${props => props.full ? "100%" : "470px"};
  margin: auto;
  height: 100%;

  @media(min-width: ${breakpoints.md}){
    width: ${props => props.full ? "100%" : "470px"};
  }
`;

const Content = styled.div`
  padding-bottom: 64.1px;
  
  @media(min-width: ${breakpoints.md}){
    display: flex;
    flex-direction: column;
    order: 1;
    padding-bottom: 31px;
  }
`;

const Details = styled.div`

  @media(min-width: ${breakpoints.md}){
    order: 2;
  }
`;


const CloseButton = styled.div`
  background-color: transparent;
  border: none;
  outline: none;
  width: 24px !important;
  height: 24px !important;
  position: fixed;
  display: grid;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  z-index: 999;
  cursor: pointer;
  img {
    width: 20px !important;
    height: 20px !important;
    object-fit: contain;
  }
  :hover {
    background-color: ${colors.neonYellow};
  }
  
  @media (min-width: ${breakpoints.md}) {
    width: 30px !important;
    height: 30px !important;
  }
`

const VideoBlock = ({block, videoIndex, currentVideoPlaying, setCurrentVideoPlaying}) => {
  const [isDescriptionVisible, showDescription] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const isPlaying = currentVideoPlaying === videoIndex;

  const handlePopup = (isOpen) => {
    setIsPopupOpen(isOpen)
    setPlay(isOpen)
  }

  const setPlay = (play) => {
    if (play === true) {
      setCurrentVideoPlaying(videoIndex);
    } else {
      setCurrentVideoPlaying(null);
    }
  }

  let thumbnail = block.thumbnail !== null && block.thumbnail.file.url;

  if (!block.video) {
    return null;
  }

  return (
    <VideoContainer full={isPopupOpen}>
      <Block full={isPopupOpen}>
        <VideoBox>
          {block.video && <Video video={block.video.file.url}
                 thumbnail={thumbnail}
                 isPlaying={isPlaying}
                 isFullScreen={isPopupOpen}
                 setPlay={setPlay}/>}
        </VideoBox>
        <ContentContainer full={isPopupOpen}>
          <InfoIcon>
            <img src={InfoImg} alt={"More info"} onClick={() => showDescription(!isDescriptionVisible)}/>
          </InfoIcon>
          <FullScreenButton onClick={() => handlePopup(true)}>Full screen</FullScreenButton>
          <Content>
            <Details>
              <Text>
                <ExternalLink href={block.linkUrl}>{block.linkLabel}</ExternalLink>
              </Text>
              <Text>{block.title}</Text>
              <Text>{block.location}</Text>
            </Details>
            <Description isDescriptionVisible={isDescriptionVisible}>
              {block.description && documentToReactComponents(block.description.json)}
            </Description>
          </Content>
        </ContentContainer>
      </Block>
      {isPopupOpen &&
      <CloseButton onClick={() => handlePopup(false)}>
        <img src={CloseIcon} alt={"Close"}/>
      </CloseButton>}
    </VideoContainer>
  )
}

export default VideoBlock
